import { template, debounce } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'

export const ESTIMATED_STYLE_TYPES = {
  OLD: 'OLD', // 到手价胶囊样式
  OLD_NO_THRESHOLD: 'OLD_NO_THRESHOLD', // 凑单到手价胶囊
  OLD_N_ESTIMATED: 'OLD_N_ESTIMATED', // N 件到手价
  NEW: 'NEW', // 到手价折扣百分比样式
  NO_THRESHOLD_FLOOR: 'NO_THRESHOLD_FLOOR', // 凑单到手价吸底楼层样式
  NEW_AND_NO_THRESHOLD_FLOOR: 'NEW_AND_NO_THRESHOLD_FLOOR', // 主价格楼层到手价百分比样式合凑单到手价吸底楼层样式同时
}

export const ESTIMATED_DATA_TYPES = {
  SATISFY: 1, // 满足门槛到手价
  NO_SATISFY: 2, // 未满足门槛到手价

  AB: 3, // AB 价

  AB_OVERLAP_SATISFY: 4, // AB 价叠加满足门槛到手价
  AB_OVERLAP_NO_SATISFY: 5, // AB 价叠加未满足门槛到手价

  N_ESTIMATED: 6,  // N 件到手价
}


export const getEstimatedInfo = ({
  skcPriceInfo: skcPriceInfo = {},
  skuPriceInfo: skuPriceInfo = {},
  isSoldOut: isSoldOut = false,
  abts: abts = {},

  otherInfo: otherInfo = {
    exclusiveInfo: null,
    sheinClubPromotionInfo: null,
    isPaidUser: false,
    complianceMode: false,
    complianceModeDe: false,
    isQuickAdd: false,
    isFlash: false,
    isNormalFlash: false, // isNormalFlash isSpecialFlash isBrandFlash 三个同时生效一个
    isSpecialFlash: false,
    isBrandFlash: false,
    isBrandDeals: false,
    language: {},
    hasLink: false, // 快速加车用回调，商详固定true
    goodsSn: '',
    realTimeReady: false,
    showNoSatisfied: true, // 快速加车用只有主商品 true，商详固定true
    closeEstimatedAndAbPrice: false,
    GB_cssRight: false
  }
}) => {
  if (otherInfo.closeEstimatedAndAbPrice) return null
  if (isSoldOut) return null
  let { EstimatedShowType, EstimatedNothreShowType, middleast, AllAreaPcsPrice } = abts || {}
  if (!!otherInfo.exclusiveInfo && !otherInfo.exclusiveInfo.isS3newpriceAbtNew) return null
  let price = skuPriceInfo || skcPriceInfo || {}
  if (!!otherInfo.exclusiveInfo) price = skcPriceInfo || {}
  let { estimatedPriceInfo, npcsEstimatedPriceInfo, estimatedPriceCalculateProcess, retailPrice } = price
  let hasUnitPrice = !!(estimatedPriceInfo?.unitCouponPrice || price.unitSalePrice) && ['showtype1', 'showtype2'].includes(AllAreaPcsPrice?.p?.DetailAreaPcsPrice)
  const { estimatedPrice, convertCouponPromotionInfo, couponDiscount, estimatedPriceOverlayAbTestPrm, needPrice, retailDiscountPrice } = estimatedPriceInfo || {}
  let { isShowAddMoreEntrance } = estimatedPriceCalculateProcess || {}
  let { language, complianceMode, complianceModeDe } = otherInfo
  let countdownEndTime = skcPriceInfo?.estimatedPriceCalculateProcess?.countdownEndTime || ''
  if (complianceMode || complianceModeDe) countdownEndTime = ''
  if(abts?.EstimatedPrice?.p?.DetailEstimatedPrice === 'unshow' && estimatedPriceInfo?.isSatisfied && convertCouponPromotionInfo?.typeId != 32){ // 当DetailEstimatedPrice=unshow，则不生效满足门槛到手价。ab到手价不受此影响。
    return null
  }

  const assignOtherInfo = (result) => {
    return {
      couponCode: estimatedPriceCalculateProcess?.multiCouponInfos?.[0]?.couponCode,
      couponDiscount,
      discountAmount: retailDiscountPrice?.amountWithSymbol || '',
      ...result,
      otherInfo,
      abts
    }
  }

  // 到手价颜色
  const getRetailPriceStyle = () => {
    const { isBrandFlash, isBrandDeals } = otherInfo
    return {
      color: (isBrandFlash || isBrandDeals) ? 'rgba(255, 255, 255, 0.8)' : '#767676'
    }
  }
  const retailPriceStyle = getRetailPriceStyle()

  /**
   * N 件到手价
   * 有就取，不看estimatedPriceInfo
   * 不用判断库存，不用判断段端配，不用判断活动限购
   * singleEstimatedPrice 单件价
   * purchasePcs 件数
  */
  if (
    npcsEstimatedPriceInfo?.singleEstimatedPrice?.amountWithSymbol &&
    !hasUnitPrice
  ) {
    return assignOtherInfo({
      value: npcsEstimatedPriceInfo.singleEstimatedPrice,
      purchasePcs: npcsEstimatedPriceInfo.purchasePcs,
      styleType: ESTIMATED_STYLE_TYPES.OLD_N_ESTIMATED,
      dataType: ESTIMATED_DATA_TYPES.N_ESTIMATED,
      buyMultipleText: {
        left: template(npcsEstimatedPriceInfo.singleEstimatedPrice.amountWithSymbol, language.SHEIN_KEY_PWA_31767 || '{0}/Pc'),
        right: template(
          `<span style="font-weight: 700">${template(npcsEstimatedPriceInfo.purchasePcs, language.SHEIN_KEY_PWA_31915 || '{0}Prc')}</span>`,
          language.SHEIN_KEY_PWA_31914 || 'With {0} purchase'
        )
      }
    })
  }
  if (!estimatedPriceInfo?.estimatedPrice?.amountWithSymbol) return null
  let { GB_cssRight } = otherInfo
  let discountPercent = GB_cssRight ? `%${couponDiscount}-` : `-${couponDiscount}%`
  let discountPercentNoMinusSign = GB_cssRight ? `%${couponDiscount}` : `${couponDiscount}%`
  /**
   * AB 价
   * convertCouponPromotionInfo 判断是 ab 价
   * estimatedPrice,couponDiscount
   * 
   * 叠加满足门槛不关注
   * 
   * 叠加未满足门槛 - 看estimatedPriceOverlayAbTestPrm.estimatedPriceType == 1
   * 底部未满足门槛的读 estimatedPriceOverlayAbTestPrm 里的 needPrice,estimatedPrice
   * 
  */
  if (convertCouponPromotionInfo?.typeId == 32) {
    let noThresholdFloor = null
    let dataType = ESTIMATED_DATA_TYPES.AB
    if (
      estimatedPriceOverlayAbTestPrm?.estimatedPrice?.amountWithSymbol &&
      estimatedPriceOverlayAbTestPrm?.estimatedPriceType == 1
    ) {
      if (
        ['New1', 'New2'].includes(EstimatedNothreShowType?.p?.EstimatedNothreShowType) &&
        !hasUnitPrice
      ) {
        noThresholdFloor = {
          value: estimatedPriceOverlayAbTestPrm?.estimatedPrice,
          needPrice: estimatedPriceOverlayAbTestPrm?.needPrice,
          discountPercent: discountPercentNoMinusSign,
          thresholdPrice: estimatedPriceOverlayAbTestPrm?.thresholdPrice,
          endTime: countdownEndTime || null,
          isShowAddMore: isShowAddMoreEntrance || false,
          EstimatedNothreShowType: EstimatedNothreShowType?.p?.EstimatedNothreShowType,
        }
      }
      dataType = ESTIMATED_DATA_TYPES.AB_OVERLAP_NO_SATISFY
    } else if (estimatedPriceOverlayAbTestPrm?.estimatedPriceType == 2) {
      dataType = ESTIMATED_DATA_TYPES.AB_OVERLAP_SATISFY
    }
    return assignOtherInfo({
      value: estimatedPrice,
      text: middleast?.p?.middleast == 'new' ? (language.SHEIN_KEY_PWA_33261 || 'with special coupon') : language.SHEIN_KEY_PWA_26364,
      discountPercent,
      styleType: !!noThresholdFloor ? ESTIMATED_STYLE_TYPES.NEW_AND_NO_THRESHOLD_FLOOR : ESTIMATED_STYLE_TYPES.NEW,
      dataType,
      noThresholdFloor,
      endTime: countdownEndTime || null,
      retailPrice,
      retailPriceStyle,
      couponCode: estimatedPriceOverlayAbTestPrm?.couponCode,
    })
  }
  let isEstimatedShowTypeNew = EstimatedShowType?.p?.EstimatedShowType === 'New'
  if (estimatedPriceInfo?.isSatisfied) {
    let text = middleast?.p?.middleast == 'new' ? language.SHEIN_KEY_PWA_32736 : template('', language?.SHEIN_KEY_PWA_24939)
    if (complianceMode || complianceModeDe) text = language.SHEIN_KEY_PWA_30800
    if (isEstimatedShowTypeNew) text = language.SHEIN_KEY_PWA_26364
    return assignOtherInfo({
      value: estimatedPrice,
      discountPercent,
      endTime: countdownEndTime || null,
      styleType: isEstimatedShowTypeNew ? ESTIMATED_STYLE_TYPES.NEW : ESTIMATED_STYLE_TYPES.OLD,
      retailPrice: isEstimatedShowTypeNew ? retailPrice : null,
      retailPriceStyle,
      dataType: ESTIMATED_DATA_TYPES.SATISFY,
      text
    })
  } else if (!hasUnitPrice) {
    if (['New1', 'New2'].includes(EstimatedNothreShowType?.p?.EstimatedNothreShowType)) {
      return assignOtherInfo({
        value: estimatedPrice,
        needPrice: estimatedPriceOverlayAbTestPrm?.needPrice,
        endTime: countdownEndTime || null,
        dataType: ESTIMATED_DATA_TYPES.NO_SATISFY,
        styleType: ESTIMATED_STYLE_TYPES.NO_THRESHOLD_FLOOR,
        noThresholdFloor: {
          value: estimatedPrice,
          needPrice: needPrice,
          discountPercent: discountPercentNoMinusSign,
          thresholdPrice: estimatedPriceOverlayAbTestPrm?.thresholdPrice,
          endTime: countdownEndTime || null,
          isShowAddMore: isShowAddMoreEntrance || false,
          EstimatedNothreShowType: EstimatedNothreShowType?.p?.EstimatedNothreShowType
        },
      })
    }
    let text = estimatedPriceInfo?.estimatedPriceMultiLang
    return assignOtherInfo({
      value: estimatedPrice,
      needPrice: couponDiscount,
      text, // 中间层已处理直接使用
      endTime: countdownEndTime || null,
      styleType: ESTIMATED_STYLE_TYPES.OLD_NO_THRESHOLD,
      dataType: ESTIMATED_DATA_TYPES.NO_SATISFY
    })
  }
  return null
}

export const DISCOUNT_PERCENT_STYLE_MAP = {
  normal: {
    color: '#FA6338',
    background: '#FFF',
    border: '1px solid #FA6338',
    'border-radius': '2px',
  },
  normalFlash: {
    background: '#FA6338',
    color: '#FFF',
    border: 'none',
    'border-radius': '0px',
  },
  specialFlash: {
    background: '#FA6338',
    color: '#fff',
    border: 'none',
    'border-radius': '0px',
  },
  brandFlash: {
    background: '#FFF',
    color: '#FF3D00',
    'font-weight': 700,
    'border-radius': '2px'
  },
  brandDeals: {
    'font-size': '10px',
    color: 'rgba(255, 255, 255, 0.80)',
    borderRadius: '2px',
    padding: '0 2px',
    border: '0.5px solid rgba(255, 255, 255, 0.80)',
  }
}

export const getUnitPriceValue = ({
  price,
  estimated_info,
  is_display_show_price
}) => {
  if (estimated_info) {
    if (estimated_info.abts?.EstimatedShowType?.p?.EstimatedShowType === 'New') return price.estimatedPriceInfo?.unitCouponPrice
    return null
  }
  if (is_display_show_price) {
    return price.unitShowPrice
  }
  return price.unitSalePrice
}

// 计算两个 dom 是否换行了
export const checkTowDomWrap = (mainDom, wrapDom) => {
  let mainDomTop = mainDom?.getBoundingClientRect?.().top
  let wrapDomTop = wrapDom?.getBoundingClientRect?.().top
  let mainDomHeight = mainDom?.getBoundingClientRect?.().height
  return (mainDomTop + mainDomHeight) <= wrapDomTop + 3
}

// 获取折扣率
export const getMainGoodsDiscountPercent = ({ discountInfo, estimatedInfo, suggestedSalePriceInfo, isHitComplianceMode, complianceTipsMode, complianceModeDe, lang_rp }) => {
  // 有到手价优先取到手价折扣率
  if (estimatedInfo?.value) {
    if ([ESTIMATED_STYLE_TYPES.NEW,  ESTIMATED_STYLE_TYPES.NEW_AND_NO_THRESHOLD_FLOOR].includes(estimatedInfo.styleType)) {
      let { discountPercent } = estimatedInfo
      // 命中到手价新样式，使用到手价折扣率
      if(discountPercent && lang_rp != 'il' && !discountPercent.includes('-')) {
        return '-' + discountPercent
      }
      return discountPercent
    }
    // 否则命中到手价老样式，不展示折扣率
    return ''
  }
  // 合规站折扣率取值规则
  if (isHitComplianceMode) {
    if (suggestedSalePriceInfo.isInversion) return ''
    if (complianceModeDe) {
      return suggestedSalePriceInfo.de.retailPriceDiscountPercent || suggestedSalePriceInfo.de.discountPercent
    }
    return complianceTipsMode ? suggestedSalePriceInfo.pl.discountPercent : suggestedSalePriceInfo.fr.discountPercent
  }
  // 原价折扣率
  return discountInfo.discountPercent
}

// 价格倒挂 优惠价小于划线价
export const checkPriceIsInversion = (expensivePrice, cheapPrice) => {
  if (!expensivePrice || !cheapPrice) {
    return true
  }
  return parseFloat(expensivePrice) <= parseFloat(cheapPrice)
}

export const debounceExpose = debounce({
  func: function (arg) {
    daEventCenter.triggerNotice(arg)
  },
  wait: 200,
})
