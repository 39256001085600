<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.9158 11.9579C23.9158 18.5587 18.5587 23.9158 11.9579 23.9158C5.35714 23.9158 0 18.5587 0 11.9579C0 5.35714 5.35714 0 11.9579 0C18.5587 0 23.9158 5.35714 23.9158 11.9579ZM18.2358 6.32573C17.7934 5.93112 17.2911 6.43335 17.2911 6.43335L9.80548 14.0027L6.80405 11.372C6.33769 10.9056 5.95504 11.1209 5.77567 11.2524C5.27344 11.659 5.68001 12.3286 5.68001 12.3286L9.05214 17.339C9.76961 18.2478 10.3675 17.3509 10.3675 17.3509L18.176 7.29432C18.5228 6.78013 18.3673 6.44531 18.2358 6.32573Z"
      fill="#5EBD66"
    />
  </svg>
</template>
