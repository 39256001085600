<script lang="jsx">
import { defineComponent } from 'vue'
/**
 * @component 一键购按钮，在商详页和加车弹窗中共用
 */
import BtnUI from './OneClickPayBtnUI'
import OrderDialog from './OrderDialog'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { CountDown } from '@shein/common-function'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'


const { currency } = gbCommonInfo

export default defineComponent({
  name: 'OneClickPayBtn',
  components: {
    OrderDialog,
    BtnUI,
  },
  props: {
    // 是否展示
    show: {
      type: Boolean,
      default: false,
    },
    // 场景
    scene: {
      type: String,
      default: 'ProductDetail',
    },
    // 一键购订单信息
    billInfo: {
      type: Object,
      default: () => ({})
    },
    // 商品详情
    goodsData: {
      type: Object,
      default: () => ({})
    },
    // 加购数量
    quantity: {
      type: Number,
      default: 1,
    },
    // 一键购的额外请求参数
    extraPayParams: {
      type: Object,
      default: () => ({})
    },
    // 点击时请求前进行的校验
    beforePay: {
      type: Function,
      default: () => (() => true)
    },
    language: {
      type: Object,
      default: () => ({})
    },
    // 一键购点击的埋点数据
    analysisData: {
      type: Object,
      default: () => ({})
    },
    // 渲染时立即加载一键购接口
    loadImmediately: {
      type: Boolean,
      default: false,
    },
    // 一键购点击的埋点数据
    abtConfigs: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      oneClickPayLoading: false,
      orderDialogVisible: false,
      relativeOrders: [],
      oneClickPayInterface: null,
      abtInfo: {},
      countDown: 0,
      countDownStr: ''
    }
  },
  computed: {
    billNo() {
      const { billInfo } = this
      return billInfo?.billno || ''
    },
    relativeOrdersShow() {
      const { scene } = this
      return scene === 'ProductDetail'
    },
    countDownAbt () {
      return this.abtInfo?.detailcountdown?.param?.detailcountdown
    },
    isShowCountdown() {
      const showList = ['showgreen', 'show']
      if(!showList.includes(this.countDownAbt)) return false
      if(this.countDown === 0) return false
      return true
    },
  },
  watch: {
    async show(val) {
      const {
        oneClickPayInterface,
        loadImmediately,
        initInterface,
      } = this
      if (val && !oneClickPayInterface && !loadImmediately) {
        // 延迟到展示时才拉取一键购接口
        // TODO-perf 最佳解决方案应为选购端提供轻量化接口
        initInterface()
      }
    },
    billInfo(val){
      if(val){
        this.countDownFun()
      }
    }
  },
  async mounted() {
    const { loadImmediately, initInterface } = this
    // 上游控制立即拉取一键购接口
    if (loadImmediately) {
      initInterface()
    }
    this.abtInfo = await getUserAbtData()
    // await this.fetchCountryData()
    this.countDownFun()
  },
  unmounted() {
    this.timer?.clear()
  },
  methods: {
    async initInterface() {
      const ocpModule = await import('public/src/pages/common/oneClickPay')
      this.oneClickPayInterface = ocpModule?.default || null
      // this.abtInfo = await getUserAbtData()
      // await this.fetchCountryData()
      // this.countDownFun()
    },
    closeDialog() {
      this.orderDialogVisible = false
    },
    async handleClick() {
      const {
        scene,
        billInfo,
        goodsData,
        oneClickPayLoading,
        beforePay,
        quantity,
        extraPayParams,
        analysisData,
        oneClickPayInterface,
        isShowCountdown
      } = this
      analysisData.deadline = +isShowCountdown
      if (oneClickPayLoading || !billInfo?.billno) {
        return
      }

      if (beforePay instanceof Function) {
        const testResult = beforePay()
        if (!testResult) {
          daEventCenter.triggerNotice({
            daId: '1-6-1-110',
            extraData: { ...(analysisData || {}) },
          })
          return
        }
      }

      if (!oneClickPayInterface) {
        // 兜底逻辑
        await this.initInterface()
      }
      if (!this.oneClickPayInterface) {
        return console.error('One Click Pay interface init failed')
      }

      // 构造请求参数
      const { paymentCode: payment_code, billno, sign_up_email, show_card_no, countryCode: country_code, payno: pay_no } = billInfo
      // TODO: sign_up_email show_card_no => 确认已废弃

      const { goods_id, goods_sn, goods_img, business_model, cat_id } = goodsData
      const payParams = {
        goods_id,
        good_sn: goods_sn,
        business_model,
        payment_code: payment_code,
        billno,
        sign_up_email,
        show_card_no,
        quantity,
        currency,
        country_code,
        goods_thumb: goods_img,
        payment_no: pay_no,
        cat_id,
        ...(extraPayParams || {}),
      }

      daEventCenter.triggerNotice({
        daId: '1-6-1-110',
        extraData: { ...(analysisData || {}) },
      })

      this.oneClickPayLoading = true
      this.oneClickPayInterface.create({
        options: {
          scene,
        },
        params: payParams,
        // 支付成功，通知更新一键购状态
        onSuccess: (successInfo) => {
          this.oneClickPayLoading = false
          this.$emit(
            'paySuccess',
            { ...(successInfo || {}), reqParams: { ...payParams } }
          )
          // daEventCenter.triggerNotice({
          //   daId: '1-6-1-110',
          //   extraData: {
          //     ...(analysisData || {}),
          //     result: 1,
          //     result_reason: 0,
          //     result_order: 1,
          //   },
          // })
        },
        // 支付接口报错或前端必要参数校验失败，更新状态并自动加购
        onFail: () => {
          this.oneClickPayLoading = false
          this.$emit('payFail')
          // daEventCenter.triggerNotice({
          //   daId: '1-6-1-110',
          //   extraData: {
          //     ...(analysisData || {}),
          //     result: 2,
          //     result_reason: err?.code || '',
          //     result_order: !!err?.failScene ? 2 : 1
          //   },
          // })
        },
        // 支付事务和交互完全结束
        onComplete: ({ type, extraData = {} } = {}) => {
          if (type === 'success') {
            const { addPurchaseRelation } = extraData
            const { all_relation_order_list } = addPurchaseRelation || {}
            if (Array.isArray(all_relation_order_list)) {
              this.relativeOrders = all_relation_order_list
            }
            this.$emit('transComplete', 'success', extraData)
            this.orderDialogVisible = true
          }
          if (type === 'fail') {
            this.$emit('transComplete', 'fail', extraData)
          }

          // 兜底
          this.oneClickPayLoading = false
        }
      })
    },
    // fetchCountryData() {
    //   return new Promise((resolve) => {
    //     const { goods_id, goods_sn } = this.goodsData
    //     schttp({
          
    //       method: 'POST',
    //       url: '/api/ocp/checkQualificationForSimple/get',
    //       data: {
    //         good_id: goods_id,
    //         good_sn: goods_sn, 
    //         billno: this.billNo
    //       }
    //     }).then((res) => {
    //       this.countDown = res.checkSimple?.info?.oneClickPayOverTime ?? 0
    //       resolve()
    //     })
    //   })
    // },
    countDownFun (){
      // TODO 之前的倒计时是不是不对，要确认下
      const endTime = this.billInfo?.oneClickPayOverTime ?? 0
      const nowTime = new Date().getTime()
      this.countDown = Math.max((+endTime  - nowTime / 1000), 0)
      // 倒计时
      this.timer = new CountDown({
        seconds: +this.countDown,
        countFunc: tiemObj => {
          const { H, M, S } = tiemObj
          this.countDownStr = `${H}:${M}:${S}`
        },
        endFunc: () => {
          this.countDown = 0
        }
      })
      this.timer.start()
    }
  },
  render() {
    const {
      show,
      language,
      relativeOrdersShow,
      orderDialogVisible,
      relativeOrders,
      billNo,
      analysisData,
      handleClick,
      closeDialog,
      countDownStr,
      countDownAbt,
      isShowCountdown
    } = this
    analysisData.deadline = +isShowCountdown
    return (
      <BtnUI
        show={show}
        language={language}
        analysisData={analysisData}
        isShowCountdown={isShowCountdown}
        countDownStr={countDownStr}
        countDownAbt={countDownAbt}
        oneClickPayMultiLan={this.billInfo?.oneClickPayMultiLan || ''}
        onClick={handleClick}
      >
        {{
          drawer: () => (
            relativeOrdersShow && (
              <OrderDialog
                visible={orderDialogVisible}
                relative-orders={relativeOrders}
                current-order-num={billNo}
                language={language}
                onClose={closeDialog}
              />
            )
          )
        }}
      </BtnUI>
    )
  },
})
</script>
