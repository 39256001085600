
<script lang="jsx">
import { defineComponent } from 'vue'
/**
 * @component 一键购按钮渲染函数
 */
export default defineComponent({
  name: 'OneClickPayBtnUI',
  props: {
    // 是否展示
    show: {
      type: Boolean,
      default: false,
    },
    // 多语言
    language: {
      type: Object,
      default: () => ({})
    },
    // 一键购点击的埋点数据
    analysisData: {
      type: Object,
      default: () => ({})
    },
    // 是否展示倒计时
    isShowCountdown: {
      type: Boolean,
      default: false,
    },
    // abt
    countDownAbt: {
      type: String,
      default: '',
    },
    // 倒计时
    countDownStr: {
      type: String,
      default: '',
    },
    // 展示文案
    oneClickPayMultiLan: {
      type: String,
      default: '',
    },
  },
  render({ $props: props, $attrs: listeners, $slots: slots }) {
    const {
      show,
      language = {},
      analysisData = {},
      isShowCountdown,
      countDownAbt,
      countDownStr,
      oneClickPayMultiLan,
    } = props
    const { onClick } = listeners

    const handleClick = (args) => {
      onClick?.(args)
    }

    const exposeInfo = { id: '1-6-1-109', data: analysisData }
    const countDown = () => (
      isShowCountdown
        ? <div v-slot:countDown class={ (countDownAbt === 'showgreen' ? 'color-g' : '') + ' product-intro__one-click-paycount'}>{ countDownStr }</div>
        : null
    )

    return (
      show ? 
        <button
          class="product-intro__one-click-pay mshe-btn-black"
          onClick={handleClick}
          v-expose={exposeInfo}
        >
          {/* { language.SHEIN_KEY_PWA_23673 } */}
          <span class="product-intro__one-click-paytxt">{ oneClickPayMultiLan || language.SHEIN_KEY_PWA_23673 }</span>
          { countDown() }
          {/** 一键购成功弹窗 */}
          {slots.drawer?.() || null}
        </button> : null
    )
  },
})
</script>

<style lang="less">
.add-cart__normal-btn-box .product-intro__one-click-pay.mshe-btn-black {
  height: 1.066667rem;
  text-align: center;
  min-width: unset;
  line-height: normal !important;
}

.quick_add__btns {
  .add-button-box {
    > .product-intro__one-click-pay {
      line-height: normal;
    }
  }
}
.product-intro__one-click-paytxt {
    font-size: 0.32rem;;
  }
.product-intro__one-click-paycount {
    display: block;
    font-size: 0.32rem;
    font-weight: 400;
    font-family: Arial;
  }
.color-g {
  color: #2BB078;
}
</style>
